

  export default {
    name: "reset-password-success-send",
    props: {
      open: {
        required: true,
        default: false,
        type: Boolean,
      },
      statusSendMail:{
        required : true,
        default : true,
        type : Boolean
      }
    },
    setup(props, { emit }) {
      const close = function (): void {
        emit("close");
      };
      return {
        close,
      };
    },
  };
  