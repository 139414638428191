
import { defineComponent, ref, computed, onMounted } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { detectSubDomain } from "@/core/helpers/helps";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import { Actions } from "@/store/enums/StoreEnums";
import SendMailResetModal from "@/components/modals/reset-password/SendMailResetModal.vue";


export default defineComponent({
  name: "request-password-reset",
  components: {
    Form,
    ElInputVeeValidate,
    SendMailResetModal
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const emailResetPassword = ref('');
    const statusModal = ref(false);
    const statusSendMail = ref(true);
    const isLoading = ref(false);

    //Create form validation object
    const requestResetPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    onMounted(() => {
      setCurrentPageTitle("Ganti Kata Sandi");
    });

    const errors = computed(() => {
      return store.getters.getErrors;
    });

    //Form submit function
    const onRequestResetPassword = () => {
      isLoading.value = true;

      store.dispatch(Actions.FORGOT_PASSWORD, {
        email : emailResetPassword.value,
        reset_password : false
      }).then(() => {
        statusModal.value = true;
        statusSendMail.value = true;
        isLoading.value = false;
      }).catch(() => {
        statusModal.value = true;
        statusSendMail.value = false;
        isLoading.value = false;
      })
    };

    function handleClose(type: string): void {
      statusModal.value = false;
      router.push('/sign-in');
    }

    return {
      onRequestResetPassword,
      requestResetPassword,
      isLoading,
      submitButton,
      errors,
      detectSubDomain,
      emailResetPassword,
      statusModal,
      handleClose,
      statusSendMail
    };
  },
});
